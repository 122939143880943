
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface HirePurchase {
  housePrice: string;
  downPayment: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  name: "housing-loan",
  components: {
    ErrorMessage,
    Field,
    Form,
    ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const monthlyPrincipal = 0;
    const monthlyInterest = 0;
    const monthlyRepayment = 0;
    const totalInterest = 0;
    const yearlyPrincipal = 0;
    const yearlyInterest = 0;
    const yearlyRepayment = 0;
    const submitButton1 = ref<HTMLElement | null>(null);
    const housingLoanCalculated = false;

    const monthlyPaymentSchedule = {
      month: "",
      repayment: 0,
      principal: 0,
      interest: 0,
      balance: 0,
      cumulativeInterest: 0,
    };

    const yearlyPaymentSchedule = {
      year: "",
      repayment: 0,
      principal: 0,
      interest: 0,
      balance: 0,
      cumulativeInterest: 0,
    };

    const paymentScheduleYearly = [];
    const paymentScheduleMonthly = [];

    return {
      id: route.params.id ?? null,
      store: useStore(),
      monthlyPrincipal,
      monthlyInterest,
      monthlyRepayment,
      totalInterest,
      yearlyPrincipal,
      yearlyInterest,
      yearlyRepayment,
      submitButton1,
      housingLoan: {
        housePrice: "500000",
        downPayment: "50000",
        loanPeriod: 30,
        interestRate: 4.2,
      },
      monthlyPaymentSchedule,
      yearlyPaymentSchedule,
      paymentScheduleYearly,
      paymentScheduleMonthly,
      housingLoanCalculated,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const housingLoanValidator = Yup.object({
      raw: Yup.object({
        housePrice: Yup.string().required().label("House Price"),
        loanPeriod: Yup.number().required().label("Loan Period"),
        interestRate: Yup.number().required().label("Interest Rate"),
      }),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Housing Loan", ["Financial Calculators"]);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const activeName = ref("stepUp1");

    const handleClick = (tab: string, event: Event) => {
      console.log(tab, event);
    };

    return {
      housingLoanValidator,
      translate,
      nFormat,
      activeName,
      handleClick,
    };
  },
  methods: {
    init() {
      if (this.id) {
        this.store.dispatch(Actions.GET_CALCULATOR, this.id).then(() => {
          this.housingLoan = this.store.getters.getCalculatorData.raw;
          this.housingLoan.housePrice = this.housingLoan.housePrice.replace(
            ",",
            ""
          );
          this.housingLoan.downPayment = this.housingLoan.downPayment.replace(
            ",",
            ""
          );
          this.calculate();
        });
      }
    },
    calculate() {
      this.paymentScheduleYearly = [];
      this.paymentScheduleMonthly = [];

      if (!this.housingLoan.downPayment) {
        this.housingLoan.downPayment = "0";
      }

      const loanAmount =
        parseFloat(this.housingLoan.housePrice) -
        parseFloat(this.housingLoan.downPayment);
      const loanPeriod = this.housingLoan.loanPeriod;
      const monthlyLoanPeriod = loanPeriod * 12;
      const interestRate = this.housingLoan.interestRate;

      const totalInterest = (interestRate / 100) * loanAmount * loanPeriod;
      const totalLoanPlusInterest = loanAmount + totalInterest;
      const monthlyInterest = totalInterest / monthlyLoanPeriod;
      const monthlyInstallment = totalLoanPlusInterest / monthlyLoanPeriod;
      const monthlyPrincipal = monthlyInstallment - monthlyInterest;

      // this.monthlyInterest = parseFloat(monthlyInterest.toFixed(5));
      // this.monthlyPrincipal = parseFloat(monthlyPrincipal.toFixed(5));

      this.monthlyInterest = monthlyInterest;
      this.monthlyPrincipal = monthlyPrincipal;

      let totalYearlyPrincipal = 0;
      let totalYearlyInterest = 0;
      let totalYearlyRepayment = 0;

      let yearCount = 0;
      let monthCount = 0;
      let interest = 0;
      let endingBalance = loanAmount;
      let principal = 0;
      let cumulativeInterest = 0;

      const P = loanAmount;
      const r = interestRate / 100 / 12;
      const n = monthlyLoanPeriod;
      const no1 = P * r;
      const no2 = Math.pow(1 + r, n);
      const no3 = no2 - 1;

      const m = (no1 * no2) / no3;
      // this.monthlyRepayment = parseFloat(m.toFixed(5));
      this.monthlyRepayment = m;

      for (let m = 0; m < monthlyLoanPeriod; m++) {
        interest = (endingBalance * (interestRate / 100)) / 12;
        cumulativeInterest += interest;

        //After Checking With Kayin
        //Remove Last Payment Method

        // if (m + 1 < monthlyLoanPeriod) {
        //   principal = this.monthlyRepayment - interest;
        //   endingBalance -= principal;

        //   this.monthlyPaymentSchedule = {
        //     month: this.ordinal_suffix_of(m + 1),
        //     repayment: this.monthlyRepayment,
        //     principal: principal,
        //     interest: interest,
        //     balance: endingBalance,
        //     cumulativeInterest: cumulativeInterest,
        //   };
        // } else {
        //   principal = endingBalance - interest;

        //   this.monthlyPaymentSchedule = {
        //     month: this.ordinal_suffix_of(m + 1),
        //     repayment: endingBalance,
        //     principal: principal,
        //     interest: interest,
        //     balance: 0,
        //     cumulativeInterest: cumulativeInterest,
        //   };
        // }

        principal = this.monthlyRepayment - interest;
        endingBalance -= principal;

        this.monthlyPaymentSchedule = {
          month: this.ordinal_suffix_of(m + 1),
          repayment: this.monthlyRepayment,
          principal: principal,
          interest: interest,
          balance: endingBalance,
          cumulativeInterest: cumulativeInterest,
        };
        //After Checking With Kayin

        this.paymentScheduleMonthly.push(this.monthlyPaymentSchedule);

        totalYearlyPrincipal += principal;
        totalYearlyInterest += interest;
        totalYearlyRepayment += this.monthlyRepayment;
        this.totalInterest = totalYearlyInterest;

        monthCount += 1;
        if (monthCount == 12) {
          yearCount += 1;

          let yearBalance = 0;

          //After Checking With Kayin
          //Remove Last Payment Method

          // if (m + 1 < monthlyLoanPeriod) {
          //   yearBalance = endingBalance;
          // } else {
          //   yearBalance = 0;
          // }

          yearBalance = endingBalance;

          //After Checking With Kayin

          this.yearlyPaymentSchedule = {
            year: this.ordinal_suffix_of(yearCount),
            repayment: totalYearlyRepayment,
            principal: totalYearlyPrincipal,
            interest: totalYearlyInterest,
            balance: yearBalance,
            cumulativeInterest: cumulativeInterest,
          };

          this.paymentScheduleYearly.push(this.yearlyPaymentSchedule);

          monthCount = 0;
        }
      }

      this.housingLoanCalculated = true;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    onSubmit(values) {
      console.log(values);
      this.store.dispatch(Actions.STORE_CALCULATOR, values).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Calculator has been saved!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
